$(function () {
    $(".banner-main-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        dots: false,
        arrows: true,
        prevArrow: $(".btn__banner-prev"),
        nextArrow: $(".btn__banner-next"),
        autoplay: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 500,
                    dots: false,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    speed: 500,
                    dots: false,
                },
            },
        ],
    });

    /* Hiệu ứng chạy chữ tìm kiếm */

    $(".button_search").on("click", function () {
        $(".show__search").toggle();
    });

    $(".button__search").on("click", function () {
        $(".overlay__box").fadeIn();
    });

    $(".overlay__box").click(function (event) {
        if ($(event.target).hasClass("overlay__box")) {
            $(".overlay__box").fadeOut(600);
        }
    });
});

$(window).scroll(function () {
    if ($(this).scrollTop()) {
        $(".nav__active, .header__active")
            .addClass("bg-[#fff] shadow-full")
            .removeClass("bg-transparent");
    } else {
        $(".nav__active, .header__active")
            .removeClass("bg-[#fff] shadow-full")
            .addClass("bg-transparent");
    }
});
$(document).ready(function() {
    $('.group__product-wrapper').hover(
        function() {
            $(this).next('.show__menusub-product').removeClass('hidden');
        },
        function() {
            $(this).next('.show__menusub-product').addClass('hidden');
        }
    );

    $('.show__menusub-product').hover(
        function() {
            $(this).removeClass('hidden');
        },
        function() {
            $(this).addClass('hidden');
        }
    );
});
